import Article from "@/models/Article";

export const SET_ALL_ARTICLES = 'setAllArticles'

export const SET_ARTICLE = 'setArticle'

export const mutations = {
  setAllArticles(state, payload) {
    console.log('setAllArticles payload', payload);
    state.allArticles = payload ? {...payload} : null;
  },

  setArticle(state, payload) {
    // console.log(payload);
    state.article = new Article(payload);
  }
}
