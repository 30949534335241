import PaginationData from "@/models/dom/PaginationData";

/** Menu  **/
export const SET_SELECTED_MENU = 'setSelectedMenu'

/** Basic modal **/
export const SET_BASIC_MODAL_DATA = 'setBasicModalData';
export const SET_BASIC_MODAL_SHOWN = 'setBasicModalShown';
export const RESET_BASIC_MODAL_DATA = 'resetBasicModalData';

/** Pagination **/
export const SET_PAGINATION_DATA = 'setPaginationData';
export const RESET_PAGINATION_DATA = 'resetPaginationData';

/** API error **/
export const SET_API_ERROR = 'setApiError';

export const mutations = {
  /** Menu **/
  setSelectedMenu(state, menuId) {
    state.selectedMenuId = menuId;
  },

  /** Basic modal **/
  setBasicModalData(state, data) {
    state.basicModalOptions = data;
    state.isBasicModalShown = true;
  },
  setBasicModalShown(state, data) {
    state.isBasicModalShown = data;
  },
  resetBasicModalData(state) {
    state.basicModalOptions = {};
    state.isBasicModalShown = false;
  },

  /** Pagination **/
  setPaginationData(state, data) {
    state.paginationData = PaginationData.parseDataFromObject(data);
  },
  resetPaginationData(state) {
    state.paginationData = null;
  },

  /** API error **/
  setApiError(state, data) {
    state.apiError = data;
  }
}
