export default class Article {

  /**
   * Create an article object
   * @param {*} apiObject The api response for an Article
   */
  constructor(apiObject){
    // console.log('apiObject', apiObject);

    this.id = apiObject ? apiObject.id  : null
    this.articleTypeId = apiObject ? apiObject.articleTypeId : null
    this.title = apiObject ? apiObject.title : null
    this.descriptionSections = apiObject ? apiObject.descriptionSections : null
    this.image = apiObject ? apiObject.image : null
    this.audio = apiObject ? apiObject.audio : null
    this.videoUrl = apiObject ? apiObject.videoUrl : null
    this.podcastLink = apiObject ? apiObject.podcastUrl : null
    this.isHighlighted = apiObject ? apiObject.isHighlighted : false
    this.startDatetime = apiObject ? apiObject.startDatetime : null
    this.endDatetime = apiObject ? apiObject.endDatetime : null
    this.mapPoint = apiObject ? apiObject.mapPoint : null
    this.suitableForChildren = apiObject ? apiObject.suitableForChildren : null
    this.wheelChairAccessible = apiObject ? apiObject.wheelChairAccessible : null
    this.accessibleToVisuallyImpaired = apiObject ? apiObject.accessibleToVisuallyImpaired : null

    // this.validateObject()
  }

  /**
   * Converts the api object to a article with defined variables
   * @param {*} apiObject The api object response
   * @returns An article object
   */
  static create(apiObject){
    return new Article(apiObject)
  }

  /**
   * Validates that the neccessary data was returned.
   * Prints if data is missing.
   * @returns void
   */
  validateObject(){
    if(!this.title){
      this.printMissingValue("title")
    }
    if(!this.description){
      this.printMissingValue("desscription")
    }
    if(this.imageUrl){
      this.printMissingValue("imageUrl")
    }

    if(!this.isEvent){
      return;
    }

    if(!this.startTime){
      this.printMissingValue("startTime")
    }

    if(!this.duration){
      this.printMissingValue("duration")
    }
    if(!this.relatedPointofInterestId){
      this.printMissingValue("relatedPointofInterest")
    }
  }

  /**
   * The missing object.
   * @param {string} missingType the missing objects name
   */
  printMissingValue(missingType){
    console.log("Article object is missing the value", missingType)
  }


}
