import {createRouter, createWebHistory} from 'vue-router';
import modules from '@/router/modules';
import {ROUTE_NAMES_AUTH} from '@/router/modules/auth'
import vuexStore from "@/store";
import {SET_API_ERROR} from "@/store/mutations";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: modules
});

router.beforeEach(async (to, from, next) => {
  vuexStore.commit(SET_API_ERROR, null);

  if (to.matched.length === 0 ) {
    next({ name: ROUTE_NAMES_AUTH.LOGIN });
    return
  }
  for (const route of to.matched) {
    if (route.meta && route.meta.guard) {
      /** Let each guard handle route access **/
      const result = await route.meta.guard.handleRouteEnter();
      if (result !== true) {
        next({name: result});
        return
      }
    }
  }
  next()
  addMetaTags(to, from, next)
});

// add metatags for routes
// source with small adjustments: https://alligator.io/vuejs/vue-router-modify-head/
function addMetaTags(to, from, next) {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))
  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return false
  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta')
    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key])
    })
    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')
    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))
}

export default router;
