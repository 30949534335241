import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import OpenLayersMap from 'vue3-openlayers'
import apiHandler from "@/utils/handlers/ApiHandler";

/** language manager **/
import {setup} from '@/setup/lang';

/** Set up the api interceptors **/
apiHandler.setInterceptors(store);
/** Set up the VueRouter **/
apiHandler.setRouter(router);

export const app = createApp(App)

app
  .use(store)
  .use(router)
  .mount('#app')
;

app.use(OpenLayersMap)

setup();

// console.log('process.env.VUE_APP_API_URL', process.env.VUE_APP_API_URL)
