import BaseRule from "@/utils/yo-validator/rules/BaseRule";

export default class RuleMaxRichTextLength extends BaseRule {
  constructor() {
    super();
  }

  validate(fieldName, formData, length) {
    /** if length isn't provided, then don't validate, return true, let the user know "it doesn't work" **/
    if (!length) return true;
    const fieldData = formData[fieldName];
    const trimmed = fieldData.replace(/<\/?[^>]+(>|$)/g, '');
    return trimmed.length <= length;
  }

  parseMessage(message, ruleName, ruleLimit) {
    return super.parseStandardMessage(message, ruleName, ruleLimit)
  }
}