import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state = {
  allArticles: null,
  article: null
}

const namespaced = false

export const articles = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
