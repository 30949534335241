<template>
  <router-view/>
  <LoadingIndicator></LoadingIndicator>
</template>

<script>
import LoadingIndicator from '@/components/basic/LoadingIndicator'
export default {
  name: 'App',
  components: { LoadingIndicator }
}
</script>

<style lang="scss">
@import "./assets/css/_base.variables.scss";
@import "./assets/css/_base.mixins.scss";
@import "./assets/css/_layout.shared.scss";
</style>
