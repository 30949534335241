import Base from "@/utils/lang/handlers/Base";

const PLACEHOLDER_COUNT = '{count}';

class ZeroMultiple extends Base {
  constructor() {
    super();
  }

  handleTranslation(translationArray, translationKey, pluralValue, params) {
    if (pluralValue < 0) {
      console.error(`The count for "${translationKey}" cannot be negative`);
      return '';
    }
    pluralValue = pluralValue > 2 ? 2 : pluralValue;
    const parsedString = translationArray[pluralValue].replace(PLACEHOLDER_COUNT, pluralValue).trim();
    return this.replaceParams(parsedString, params);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ZeroMultiple();
    }
    return this.instance;
  }
}

export default ZeroMultiple.getInstance();