export default class PaginationData {
  constructor({next, last, current}) {
    this.next = next;
    this.last = last;
    this.current = current;
  }

  static parseDataFromObject(data) {
    return new PaginationData(data);
  }
}