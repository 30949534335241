import AuthGuard from '@/router/guards/auth';

const Layout = () => import("@/views/Layout.vue");
const EventOverview = () => import("@/views/events/EventsOverview.vue")
const EventDetails = () => import("@/views/articles/ArticleDetails.vue")
/**
 * Enum for the 'home'-module route names.
 * @enum
 */
export const ROUTE_NAMES_EVENTS = Object.freeze({
  OVERVIEW: 'EventOverview',
  CREATE: 'EventCreate',
  EDIT: 'EventEdit',
});

export const events = {
  path: "/events",
  meta: {
    guard: AuthGuard
  },
  component: Layout,
  children: [
    {
      path: "overview",
      name: ROUTE_NAMES_EVENTS.OVERVIEW,
      component: EventOverview,
      meta: {
        title: 'Evenementen – Marinedagen 2022 CMS',
      }
    },
    {
      path: "create",
      name: ROUTE_NAMES_EVENTS.CREATE,
      component: EventDetails,
      meta: {
        title: 'Evenement aanmaken – Marinedagen 2022 CMS',
        isEvent: true
      }
    },
    {
      path: "edit/:id",
      name: ROUTE_NAMES_EVENTS.EDIT,
      component: EventDetails,
      meta: {
        title: 'Evenement aanpassen – Marinedagen 2022 CMS',
        isEvent: true
      }
    }
  ]
}
