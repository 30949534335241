import {createStore} from 'vuex';
import {mutations} from './mutations';
import {actions} from './actions';
import {getters} from './getters';

import {auth} from './auth';
import {articles} from './articles';
import {mapPoints} from './mapPoints';

export const state = {
  /** Menu **/
  selectedMenuId: 1,
  /** Basic modal **/
  basicModalOptions: {},
  isBasicModalShown: false,
  /** Pagination **/
  paginationData: null,
  /** API errors **/
  apiError: null
};

const store = {
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    articles,
    mapPoints
  }
}

// create the store
const vuexStore = createStore(store);

export default vuexStore;
