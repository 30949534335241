<template>
  <div id="loading-indicator">
    <svg class="spinner"
         width="65px"
         height="65px"
         viewBox="0 0 66 66"
    >
      <circle class="path"
              fill="none"
              stroke-width="6"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
      ></circle>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'LoadingIndicator',
  // props: {
  //   isLoading: {
  //     type: Boolean,
  //     default: false,
  //   }
  // },
  // setup(props) {
  // }
}

</script>

<style lang="scss" scoped>
  #loading-indicator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    z-index: 10000;
  }

  $offset: 187;
  $duration: 1.4s;

  .spinner {
    animation: rotator $duration linear infinite;
  }

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: var(--primary-light);
    animation:
      dash $duration ease-in-out infinite
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
    }
    50% {
      stroke-dashoffset: $offset/4;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform: rotate(450deg);
    }
  }
</style>
