const Layout = () => import('@/views/auth/Layout');
const Login = () => import('@/views/auth/Login.vue');

export const ROUTE_NAMES_AUTH = Object.freeze({
  AUTH: 'Auth',
  LOGIN: 'AuthLogin',
});

export const auth = {
  path: '/auth',
  name: ROUTE_NAMES_AUTH.AUTH,
  component: Layout,
  children: [
    {
      path: 'login',
      name: ROUTE_NAMES_AUTH.LOGIN,
      meta: {
        title: 'Inloggen – Marinedagen 2022 CMS',
      },
      component: Login
    },
  ]
};
