import Base from "@/utils/lang/handlers/Base";

class Singular extends Base {
  constructor() {
    super();
  }

  handleTranslation({selectedLanguage, translationKey, params}) {
    let translation = selectedLanguage[translationKey];
    if (!translation) {
      console.error(`Double check the message for translation key: ${translationKey} in your json file or view template`);
      return '';
    }
    return this.replaceParams(translation, params);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new Singular();
    }
    return this.instance;
  }
}

export default Singular.getInstance();