export default class MapPoint {


  /**
   * Creates a map object.
   * @param {*} apiObject The api response for a map point.
   */
  constructor(apiObject){
    this.id = apiObject ? apiObject.id : null
    this.locationNumber = apiObject ? apiObject.locationNumber : null
    this.title = apiObject ? apiObject.title : null
    this.subtitle = apiObject ? apiObject.subtitle : null
    this.descriptionSections = apiObject ? apiObject.descriptionSections : null
    this.lat = apiObject ? apiObject.lat : null
    this.lng = apiObject ? apiObject.lng : null
    this.mapPointTypeId = apiObject ? apiObject.mapPointTypeId : null
    this.image = apiObject ? apiObject.image : null
    this.audio = apiObject ? apiObject.audio : null
    this.videoUrl = apiObject ? apiObject.videoUrl : null
    this.podcastLink = apiObject ? apiObject.podcastLink : null
    this.suitableForChildren = apiObject ? apiObject.suitableForChildren : null
    this.wheelChairAccessible = apiObject ? apiObject.wheelChairAccessible : null
    this.accessibleToVisuallyImpaired = apiObject ? apiObject.accessibleToVisuallyImpaired : null
    this.availabilityType = apiObject ? apiObject.availabilityType + 1 : null
    this.openingTimes = apiObject ? apiObject.openingTimes : null

    // this.validateObject();
  }

  /**
   * Converts the api object to a map point with defined variables
   * @param {*} apiObject the api object response.
   * @returns a map point
   */
  static create(apiObject){
    return MapPoint(apiObject)
  }

  /**
   * Check that all required elements exist.
   */
  validateObject(){
    if(!this.title){
      this.printMissingValue("title")
    }
    if(!this.subtitle){
      this.printMissingValue("subtitle")
    }
    if(!this.shortDescription){
      this.printMissingValue("short description")
    }
    if(!this.fullDescription){
      this.printMissingValue("full description")
    }
    if(!this.longitude){
      this.printMissingValue("longitude")
    }
    if(!this.latitude){
      this.printMissingValue("latitude")
    }
    if(!this.categoryId){
      this.printMissingValue("category id")
    }
    if(!this.imageUrl){
      this.printMissingValue("image url")
    }
  }

  /**
   * The missing object.
   * @param {string} missingType the missing objects name
   */
  printMissingValue(missingType){
    console.log("Map point object is missing the value", missingType)
  }
}
