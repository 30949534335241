import LocalDataHandler from "@/utils/handlers/LocalDataHandler";
import apiHandler from '@/utils/handlers/ApiHandler'
import MapPoint from "@/models/MapPoint";

export const SET_ALL_MAP_POINTS = 'setAllMapPoints'

export const SET_MAP_POINT = 'setMapPoint'

export const mutations = {
  setAllMapPoints(state, payload) {
    state.allMapPoints = payload;
  },

  setMapPoint(state, payload) {
    state.mapPoint = new MapPoint(payload);
  }
}
