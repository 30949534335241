import AuthGuard from '@/router/guards/auth';

const Layout = () => import("@/views/Layout.vue");
const ArticleOverview = () => import("@/views/articles/ArticleOverview.vue")
const ArticleDetails = () => import("@/views/articles/ArticleDetails.vue")
/**
 * Enum for the 'home'-module route names.
 * @enum
 */
export const ROUTE_NAMES_ARTICLES = Object.freeze({
  OVERVIEW: 'ArticleOverview',
  CREATE: 'ArticleCreate',
  EDIT: 'ArticleEdit',
});

export const articles = {
  path: "/articles",
  meta: {
    guard: AuthGuard
  },
  component: Layout,
  children: [
    {
      path: "overview",
      name: ROUTE_NAMES_ARTICLES.OVERVIEW,
      component: ArticleOverview,
      meta: {
        title: 'Artikelen – Marinedagen 2022 CMS',
      }
    },
    {
      path: "create",
      name: ROUTE_NAMES_ARTICLES.CREATE,
      component: ArticleDetails,
      meta: {
        title: 'Artikel aanmaken – Marinedagen 2022 CMS',
      }
    },
    {
      path: "edit/:id",
      name: ROUTE_NAMES_ARTICLES.EDIT,
      component: ArticleDetails,
      meta: {
        title: 'Artikel aanpassen – Marinedagen 2022 CMS',
      }
    }
  ]
}
