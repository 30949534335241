import {LOGIN_SUCCESS} from "@/store/auth/mutations";
import LocalDataHandler from '@/utils/handlers/LocalDataHandler';
import apiHandler from '@/utils/handlers/ApiHandler';

export const SEND_LOGIN = 'sendLogin';

export const actions = {
  sendLogin({commit}, data) {
    // Create the form data:
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    formData.append('client_secret', process.env.VUE_APP_AUTH_CLIENT_SECRET);
    // todo: decide to set username or email depends on back end setup
    formData.append('username', data.username);
    formData.append('password', data.password);
    formData.append('2fa_code', data.twoFaCode);

    return apiHandler.post('auth/login', formData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(LOGIN_SUCCESS, Object.assign(data, response.data));
        apiHandler.setAuthHandler(true);
        LocalDataHandler.setAuthenticated();
        return true;
      }
      return false;
    });
  },
}
