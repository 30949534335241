/** routes **/
import {articles} from '@/router/modules/article'
import {events} from '@/router/modules/event'
import {map} from '@/router/modules/map'
import {auth} from '@/router/modules/auth'
import NotFound from '@/views/NotFound'
import Logout from "@/views/Logout";

export const ROUTE_NAMES_ROOT = Object.freeze({
  HOME: 'Home',
  LOGOUT: 'Logout',
  NOT_FOUND: 'Not Found'
});

/** routes without layout **/
export const root = [
  {
    path: '/logout',
    name: ROUTE_NAMES_ROOT.LOGOUT,
    component: Logout,
    meta: {
      title: 'Uitloggen – Marinedagen 2022 CMS',
    }
  },
  {
    // not found
    path: '/not-found',
    name: ROUTE_NAMES_ROOT.NOT_FOUND,
    component: NotFound,
    meta: {
      title: 'Pagina niet gevonden – Marinedagen 2022 CMS',
    }
  },
]

export default root.concat([articles, events, map, auth]);
