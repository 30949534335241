import defaultMessageLangs from '@/utils/yo-validator/messages'
import RuleRequired from '@/utils/yo-validator/rules/RuleRequired'
import RuleEmail from '@/utils/yo-validator/rules/RuleEmail'
import RuleMaxSize from '@/utils/yo-validator/rules/RuleMaxSize'
import RuleMaxLength from '@/utils/yo-validator/rules/RuleMaxLength'
import RuleMaxRichTextLength from "@/utils/yo-validator/rules/RuleMaxRichTextLength";
import RuleMax from '@/utils/yo-validator/rules/RuleMax'
import RuleMin from '@/utils/yo-validator/rules/RuleMin'
import RulePasswordConfirmation from '@/utils/yo-validator/rules/RulePasswordConfirmation'
import RulePasswordStrength from '@/utils/yo-validator/rules/RulePasswordStrength'

import RulePasswordLength from '@/utils/yo-validator/rules/RulePasswordLength'
import RulePasswordLowercase from '@/utils/yo-validator/rules/RulePasswordLowercase'
import RulePasswordNumber from '@/utils/yo-validator/rules/RulePasswordNumber'
import RulePasswordUppercase from '@/utils/yo-validator/rules/RulePasswordUppercase'
import RulePasswordSpecialCharacter from '@/utils/yo-validator/rules/RulePasswordSpecialCharacter'

import RuleDutchPhoneNumber from '@/utils/yo-validator/rules/RuleDutchPhoneNumber'

import RuleExtension from '@/utils/yo-validator/rules/RuleExtension'
import RuleMinSize from '@/utils/yo-validator/rules/RuleMinSize'
import RuleMinLength from '@/utils/yo-validator/rules/RuleMinLength'
import RuleAlpha from '@/utils/yo-validator/rules/RuleAlpha'
import RuleAlphaNumeric from '@/utils/yo-validator/rules/RuleAlphaNumeric'
import RuleNumeric from '@/utils/yo-validator/rules/RuleNumeric'

const DEFAULT_LANG = 'nl'
const FIELD_NAME_PLACEHOLDER = '{fieldName}'

class ValidationRules {
  constructor() {
    this.selectLanguage = DEFAULT_LANG
    this.messageLangs = defaultMessageLangs
    this.ruleList = {
      required: new RuleRequired(),
      alpha: new RuleAlpha(),
      alphaNumeric: new RuleAlphaNumeric(),
      numeric: new RuleNumeric(),
      email: new RuleEmail(),
      maxSize: new RuleMaxSize(),
      minSize: new RuleMinSize(),
      ext: new RuleExtension(),
      maxLength: new RuleMaxLength(),
      minLength: new RuleMinLength(),
      maxRichTextLength: new RuleMaxRichTextLength(),
      max: new RuleMax(),
      min: new RuleMin(),
      passwordConfirm: new RulePasswordConfirmation(),
      passwordStrength: new RulePasswordStrength(),
      passwordLength: new RulePasswordLength(),
      passwordUppercase: new RulePasswordUppercase(),
      passwordLowercase: new RulePasswordLowercase(),
      passwordNumber: new RulePasswordNumber(),
      passwordSpecialCharacter: new RulePasswordSpecialCharacter(),
      dutchPhoneNumber: new RuleDutchPhoneNumber()
    }
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ValidationRules()
    }
    return this.instance
  }

  setSelectedLanguage(lang) {
    this.setValidationMessages(this.messageLangs, lang)
  }

  setValidationMessages(messageLangs, lang) {
    if (!lang || typeof lang !== 'string' || !this.messageLangs[lang.toLowerCase()]) {
      console.error('Invalid or not supported language passed, switched to default messages')
      return
    }
    lang = lang.toLowerCase()
    /** check if the messages are valid, there must be at least 1 language **/
    if (!messageLangs || typeof messageLangs !== 'object' || Object.keys(messageLangs).length < 1) {
      console.error('Invalid messages passed, switched to default messages')
      this.selectLanguage = DEFAULT_LANG
      this.messageLangs = defaultMessageLangs
      return
    }
    /** check if lang doesn't exist in passed messages **/
    if (!Object.keys(messageLangs).map(name => name.toLowerCase()).includes(lang)) {
      console.error('Language doesn\'t exist in passed messages, switched to default messages')
      this.selectLanguage = DEFAULT_LANG
      this.messageLangs = defaultMessageLangs
      return
    }
    /** check if there is any unsupported language **/
    this.selectLanguage = lang
    this.messageLangs = messageLangs
  }

  getErrorMessage(fieldName, ruleName, ruleLimit) {
    /** define select and default packages **/
    const selectedPackage = this.messageLangs[this.selectLanguage]
    const defaultPackage = defaultMessageLangs[this.selectLanguage]
      ? defaultMessageLangs[this.selectLanguage]
      : defaultMessageLangs[DEFAULT_LANG]
    /** check if messages and rule name are defined **/
    if (!selectedPackage.messages || !selectedPackage.messages[ruleName]) {
      console.error('Messages or rule name isn\'t defined, switched to default/general error')
      const error = defaultPackage.messages[ruleName].default
      return this.parseMessage(error, fieldName, ruleName, ruleLimit)
    }
    /** get error user defined: required.username > required.default > default: default.required **/
    const error = selectedPackage.messages[ruleName][fieldName] ||
      selectedPackage.messages[ruleName].default ||
      defaultPackage.messages[ruleName].default
    return this.parseMessage(error, fieldName, ruleName, ruleLimit)
  }

  parseMessage(message, fieldName, ruleName, ruleLimit) {
    const selectedPackage = this.messageLangs[this.selectLanguage]
    // get defined username or just use the key name to replace
    fieldName = selectedPackage.names && selectedPackage.names[fieldName] ? selectedPackage.names[fieldName] : fieldName
    // replace {fieldName}, for instance {fieldName} -> username
    message = message.replace(FIELD_NAME_PLACEHOLDER, fieldName)
    // replace {max} by 8 for instance, if needed
    const ruleValidator = this.ruleList[ruleName]
    // parse message according to different rules
    message = ruleValidator.parseMessage(message, ruleName, ruleLimit)
    return message
  }

  getValidator(ruleName) {
    return this.ruleList[ruleName]
  }
}

export default ValidationRules.getInstance()
