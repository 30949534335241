export default {
  messages: {
    required: {
      default: 'Vereist om te vullen',

      // Shared
      name: 'Er dient een naam te worden opgegeven.',
      email: 'Er dient een e-mailadres te worden opgegeven.',
      password: 'Er dient een wachtwoord te worden opgegeven.',
      currentPassword: 'Er dient een wachtwoord te worden opgegeven.',
      confirmation: 'Er dient een herhaling te worden opgegeven.',
    },
    alpha: {
      default: 'Uw {fieldName} mag alleen alfabetten bevatten.'
    },
    alphaNumeric: {
      default: 'Uw {fieldName} mag alleen letters en cijfers bevatten.'
    },
    numeric: {
      default: 'Uw {fieldName} mag alleen cijfers bevatten.'
    },
    email: {
      default: 'Uw {fieldName} is geen geldig e-mailadres.'
    },
    ext: {
      default: 'De extensie van uw {fieldName} mag alleen {ext} zijn.',
    },
    max: {
      default: 'Uw {fieldName} mag niet groter zijn dan {max}.'
    },
    min: {
      default: 'Uw {fieldName} mag niet kleiner zijn dan {min}.'
    },
    maxLength: {
      default: 'Uw {fieldName} mag niet langer zijn dan {maxLength} tekens.'
    },
    minLength: {
      default: 'Uw {fieldName} mag niet korter zijn dan {minLength} tekens.'
    },
    maxRichTextLength: {
      default: 'Uw {fieldName} mag niet langer zijn dan {maxRichTextLength} tekens.'
    },
    maxSize: {
      default: 'Uw {fieldName} mag niet groter zijn dan {maxSize}MB.'
    },
    minSize: {
      default: 'Uw {fieldName} mag niet kleiner zijn dan {minSize}MB.'
    },
    passwordConfirm: {
      default: 'Uw wachtwoorden komen niet overeen.'
    },
    passwordStrength: {
      default: 'Het wachtwoord moet uit tenminste 6 tekens bestaan.'
    },
    passwordLength: {
      default: 'Een wachtwoord moet uit tenminste 6 tekens bestaan.'
    },
    passwordNumber: {
      default: 'Het wachtwoord moet tenminste één cijfer bevatten.'
    },
    passwordUppercase: {
      default: 'Het wachtwoord moet tenminste één hoofdletter bevatten.'
    },
    passwordLowercase: {
      default: 'Het wachtwoord moet tenminste één kleine letter bevatten.'
    },
    passwordSpecialCharacter: {
      default: 'Het wachtwoord moet tenminste één speciaal teken bevatten.'
    },
    dutchPhoneNumber: {
      default: 'Geef een geldig Nederlands telefoonnummer op.'
    },
  }
}
