import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state = {
  allMapPoints: [],
  mapPoint: null,
}

const namespaced = false

export const mapPoints = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
