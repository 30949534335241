import { SET_ALL_ARTICLES, SET_ARTICLE } from "@/store/articles/mutations";
import { SET_PAGINATION_DATA } from "@/store/mutations";
import LocalDataHandler from '@/utils/handlers/LocalDataHandler';
import apiHandler from '@/utils/handlers/ApiHandler';

/** Get all **/
export const GET_ARTICLES = 'getArticles';
/** Get by id **/
export const GET_ARTICLE_BY_ID = 'getArticleById';
/** Create **/
export const CREATE_ARTICLE = 'createArticle';
/** Edit **/
export const EDIT_ARTICLE = 'editArticle';
/** Delete **/
export const DELETE_ARTICLE = 'deleteArticle';
/** Reorder **/
export const REORDER_ARTICLES = 'reorderArticles';
/** Upload files **/
export const UPLOAD_FILES = 'uploadFiles';

export const actions = {
  /** Get all **/
  getArticles({commit}, params) {
    return apiHandler.get(`article/get-paginated`, apiHandler.getAuthHeader(), params).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_ALL_ARTICLES, response.data);
        commit(SET_PAGINATION_DATA, { next: response.data.currentPage == response.data.totalPages ? response.data.currentPage : response.data.currentPage + 1,
                                      last: response.data.totalPages, 
                                      current: response.data.currentPage });
        return true;
      }
      return false;
    });
  },

  /** Get by id **/ //
  getArticleById({commit}, id) {
    return apiHandler.get(`article/get/${id}`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_ARTICLE, response.data);
        return true;
      }
      return false;
    });
  },

  /** Create **/
  createArticle({commit}, payload) {
    const formData = new FormData();
    Object.keys(payload).map((key) => {
      if (key == 'descriptionSections') {
        for (var i = 0; i < payload.descriptionSections.length; i++) {
          Object.keys(payload.descriptionSections[i]).map(key => {
            formData.append(`descriptionSections[${i}][${key}]`, payload.descriptionSections[i][key]);
          })
        }
      }
      else {
        formData.append(key, payload[key])
      }
    })

    return apiHandler.post('article/create', formData, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    });
  },

  /** Edit **/
  editArticle({commit}, payload) {
    const formData = new FormData();
    Object.keys(payload).map((key) => {
      if (key == 'descriptionSections') {
        for (var i = 0; i < payload.descriptionSections.length; i++) {
          Object.keys(payload.descriptionSections[i]).map(key => {
            formData.append(`descriptionSections[${i}][${key}]`, payload.descriptionSections[i][key]);
          })
        }
      }
      else {
        formData.append(key, payload[key])
      }
    })

    return apiHandler.post(`article/edit/${payload.id}`, formData, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    });
  },

  /** Delete **/
  deleteArticle({commit}, id) {
    return apiHandler.delete(`article/delete/${id}`, {}, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    });
  },

  /** Reorder **/
  reorderArticles({commit}, payload) {
    const formData = new FormData();
    formData.append('newArticleSortIndex', payload.newIndex);

    return apiHandler.post(`article/reorder/${payload.id}`, formData, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    });
  },

    /** Upload files **/
    uploadFiles({commit}, file) {
      const formData = new FormData();
      formData.append('file', file);
  
      return apiHandler.post(`media-file/upload`, formData, apiHandler.getAuthHeader()).then(response => {
        if (apiHandler.isSuccess(response.status)) {
          return response.data;
        }
        return false;
      });
    },
}
