import { SET_ALL_MAP_POINTS, SET_MAP_POINT } from "@/store/mapPoints/mutations";
import { SET_PAGINATION_DATA } from "@/store/mutations";
import apiHandler from '@/utils/handlers/ApiHandler';

/** Get all **/
export const GET_MAP_POINTS = 'getMapPoints';
/** Get by id **/
export const GET_MAP_POINT_BY_ID = 'getMapPointById';
/** Create **/
export const CREATE_MAP_POINT = 'createMapPoint';
/** Edit **/
export const EDIT_MAP_POINT = 'editMapPoint';
/** Delete **/
export const DELETE_MAP_POINT = 'deleteMapPoint';

export const actions = {
  /** Get all **/
  getMapPoints({commit}, params) {
    return apiHandler.get('map-point/get-paginated', apiHandler.getAuthHeader(), params).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_ALL_MAP_POINTS, response.data);
        commit(SET_PAGINATION_DATA, { next: response.data.currentPage === response.data.totalPages ? response.data.currentPage : response.data.currentPage + 1,
                                      last: response.data.totalPages,
                                      current: response.data.currentPage });
        return true;
      }
      return false;
    });
  },

  /** Get by id **/ //
  getMapPointById({commit}, id) {
    return apiHandler.get(`map-point/get/${id}`, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        commit(SET_MAP_POINT, response.data);
        return true;
      }
      return false;
    });
  },

  /** Create **/
  createMapPoint({commit}, payload) {
    const formData = new FormData();
    Object.keys(payload).map((key) => {
      if (key == 'openingTimes') {
        for (var i = 0; i < payload.openingTimes.length; i++) {
          Object.keys(payload.openingTimes[i]).map(key => {
            formData.append(`openingTimes[${i}][${key}]`, payload.openingTimes[i][key]);
          })
        }
      }
      else if (key == 'descriptionSections') {
        if (payload.descriptionSections) {
          for (var i = 0; i < payload.descriptionSections.length; i++) {
            Object.keys(payload.descriptionSections[i]).map(key => {
              formData.append(`descriptionSections[${i}][${key}]`, payload.descriptionSections[i][key]);
            })
          }
        }
      }
      else {
        formData.append(key, payload[key])
      }
    })

    return apiHandler.post('map-point/create', formData, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    });
  },

  /** Edit **/
  editMapPoint({commit}, payload) {
    const formData = new FormData();
    Object.keys(payload).map((key) => {
      if (key == 'openingTimes') {
        for (var i = 0; i < payload.openingTimes.length; i++) {
          Object.keys(payload.openingTimes[i]).map(key => {
            formData.append(`openingTimes[${i}][${key}]`, payload.openingTimes[i][key]);
          })
        }
      }
      else if (key == 'descriptionSections') {
        if (payload.descriptionSections) {
          for (var i = 0; i < payload.descriptionSections.length; i++) {
            Object.keys(payload.descriptionSections[i]).map(key => {
              formData.append(`descriptionSections[${i}][${key}]`, payload.descriptionSections[i][key]);
            })
          }
        }
      }
      else {
        formData.append(key, payload[key])
      }
    })

    return apiHandler.post(`map-point/edit/${payload.id ? payload.id : 48}`, formData, apiHandler.getAuthHeader()).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    });
  },

  /** Delete **/
  deleteMapPoint({commit}, id) {
    return apiHandler.delete(`map-point/delete/${id}`, {}, apiHandler.getAuthHeader()).then(response => {
      console.log('map point', response);
      if (apiHandler.isSuccess(response.status)) {
        return true;
      }
      return false;
    });
  },

}
