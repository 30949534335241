export default class Base {
  constructor() {
  }

  replaceParams(translation, params) {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        translation = translation.replace(`{${key}}`, params[key]);
      }
    }
    return translation;
  }
}