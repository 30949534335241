import Base from "@/utils/lang/handlers/Base";
import OneMultiple from "@/utils/lang/handlers/OneMultiple";
import ZeroMultiple from "@/utils/lang/handlers/ZeroMultiple";

const LENGTH_ONE_MULTIPLE = 2;
const LENGTH_ZERO_MULTIPLE = 3;

class Plural extends Base {
  constructor() {
    super();
    this.translationHandlers = {
      [LENGTH_ONE_MULTIPLE]: OneMultiple,
      [LENGTH_ZERO_MULTIPLE]: ZeroMultiple
    }
  }

  handleTranslation({selectedLanguage, translationKey, pluralValue, params}) {
    const translation = selectedLanguage[translationKey];
    if (!translation || pluralValue === undefined || pluralValue === null || isNaN(pluralValue)) {
      console.error(`Double check the message/count for translation key: ${translationKey} in your json file or view template`);
      return '';
    }
    // get translation array
    let translationArray = translation.split('|');
    // if the message isn't correct added, return empty
    if (!this.translationHandlers[translationArray.length]) {
      console.error(`Double check the message for translation key "${translationKey}"`);
      return '';
    }
    return this.translationHandlers[translationArray.length].handleTranslation(translationArray, translationKey, pluralValue, params)
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new Plural();
    }
    return this.instance;
  }
}

export default Plural.getInstance();