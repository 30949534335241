// import Vue from 'vue';
import {app} from '@/main'
import Validator from '@/utils/yo-validator/YoValidator';
import languages from './translations';
import Plural from "@/utils/lang/handlers/Plural";
import Singular from "@/utils/lang/handlers/Singular";

const DEFAULT_LANG = 'en';

class LanguageManager {
  constructor() {
    this.appLanguage = DEFAULT_LANG;
    this.translations = languages;
    this.selectedLanguage = languages[DEFAULT_LANG];
  }

  setAppLanguage(lang, messages) {
    this.setTranslationLanguage(lang);
    this.setValidationMessages(lang, messages);
  }

  setTranslationLanguage(lang) {
    if (!lang || typeof lang !== 'string') {
      console.error('Invalid or not supported language passed');
      return;
    }
    lang = lang.toLowerCase();
    this.appLanguage = lang;
    this.selectedLanguage = languages[lang];
    this.initializeTemplateHandlers();
  }

  initializeTemplateHandlers() {
    app.config.globalProperties.$t = this.parseTranslation.bind(this);
    app.config.globalProperties.$tc = this.parsePluralTranslation.bind(this);
  }

  /**
   * example string with 1 pipe (@/utils/lang/translations/en.js):
   * "countries": "1 person from {country} | {count} persons in {country}"
   * example string with 2 pipes (@/utils/lang/translations/en.js):
   * "countries": "no person from {country} | 1 person from {country} | {count} persons in {country}"
   * example template variable
   * {{ $tc('countries', 13, {country: 'the Netherlands'}) }}
   *
   * @param translationKey
   * @param pluralValue
   * @param params
   * @returns {*}
   */
  parsePluralTranslation(translationKey, pluralValue, params) {
    return Plural.handleTranslation({selectedLanguage: this.selectedLanguage, translationKey, pluralValue, params});
  }

  /**
   * example string (@/utils/lang/translations/en.js):
   * "country": "{description} countries",
   * example template variable
   * {{ $t('country', {description: 'beautiful'}) }}
   *
   * @param translationKey
   * @param params
   * @returns {*}
   */
  parseTranslation(translationKey, params) {
    return Singular.handleTranslation({selectedLanguage: this.selectedLanguage, translationKey, params})
  }

  setValidationMessages(lang, messages = undefined) {
    messages ? Validator.setValidationMessages(messages, lang) : Validator.setSelectedLang(lang);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new LanguageManager();
    }
    return this.instance;
  }
}

export default LanguageManager.getInstance();
