export const getters = {
    /** Menu **/
    getSelectedMenuId(state) {
      return state.selectedMenuId
    },

    /** Basic modal **/
    getBasicModalShown(state) {
      return state.isBasicModalShown;
    },
    getBasicModalOptions(state) {
      return state.basicModalOptions;
    },

    /** Pagination **/
    getPaginationData(state) {
      return state.paginationData;
    },

    /** API error **/
    getApiError(state) {
      return state.apiError;
    },
}
