import Base from "@/utils/lang/handlers/Base";

const PLACEHOLDER_COUNT = '{count}';

class OneMultiple extends Base {
  constructor() {
    super();
  }

  handleTranslation(translationArray, translationKey, pluralValue, params) {
    if (pluralValue < 1) {
      console.error(`The count for "${translationKey}" cannot be smaller than 1 if you only add the strings for 1 and multiple`);
      return '';
    }
    pluralValue = pluralValue > 2 ? 2 : pluralValue;
    const parsedString = translationArray[pluralValue - 1].replace(PLACEHOLDER_COUNT, pluralValue).trim();
    return this.replaceParams(parsedString, params);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new OneMultiple();
    }
    return this.instance;
  }
}

export default OneMultiple.getInstance();