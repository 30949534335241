import AuthGuard from '@/router/guards/auth';

const Layout = () => import("@/views/Layout.vue");
const MapOverview = () => import("@/views/map/MapOverview.vue")
const MapDetails = () => import("@/views/map/MapDetails.vue")
/**
 * Enum for the 'home'-module route names.
 * @enum
 */
export const ROUTE_NAMES_MAP = Object.freeze({
  OVERVIEW: 'MapOverview',
  CREATE: 'MapCreate',
  EDIT: 'MapEdit',
});

export const map = {
  path: "/map",
  meta: {
    guard: AuthGuard
  },
  component: Layout,
  children: [
    {
      path: "overview",
      name: ROUTE_NAMES_MAP.OVERVIEW,
      component: MapOverview,
      meta: {
        title: 'Locaties – Marinedagen 2022 CMS',
      }
    },
    {
      path: "create",
      name: ROUTE_NAMES_MAP.CREATE,
      component: MapDetails,
      meta: {
        title: 'Locatie aanmaken – Marinedagen 2022 CMS',
      }
    },
    {
      path: "edit/:id",
      name: ROUTE_NAMES_MAP.EDIT,
      component: MapDetails,
      meta: {
        title: 'Locatie aanpassen – Marinedagen 2022 CMS',
      }
    }
  ]
}
